:root {
    --primary-color: #6D3580; // Initial value
    --secoundry-color: #CC4165; // --Initial value
}

.row.justify-content-left {
    justify-content: center;
}

section {
    padding: 50px 0;
    font-size: 16px;
}

section.privacy-header {
    background-color: #485b99c1;
    position: relative;
    padding: 75px 0;
}

section.privacy-header .container {
    height: 50px;
}

.col-md-8 p:nth-last-child(1) {
    margin: 0;
}

h2.m-10px {
    margin: 20px 0;
}

.card.card-privacy {
    border-bottom: 5px solid var(--secoundry-color);
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.card.card-privacy:hover {
    color: #fff !important;
    transform: scale(1.05);
    background-color: var(--secoundry-color);
}

.card.card-privacy:hover a {
    color: #fff !important;
}

h2.section-title {
    text-transform: capitalize;
    position: relative;
    padding-bottom: 15px;
    font-weight: 400;
    text-align: center;
    line-height: inherit !important;
}

h2.section-title::after {
    width: 150px;
    height: 1px;
    display: block;
    content: "";
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--primary-color);
}

h2.section-title::before {
    content: "";
    width: 30px;
    height: 5px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: var(--primary-color);
}

.form-group {
    position: relative;
}

.form-group label:not(#size-small-outlined-label) {
    position: absolute;
    font-weight: 400;
    font-size: 14px;
    bottom: 75%;
    left: 5%;
    z-index: 2;
    background-color: #fff;
}

.form-group label#size-small-outlined-label.Mui-focused {
    font-size: 18.2px;
}

.form-group label#size-small-outlined-label {
    font-weight: 400;
    background-color: #fff;
    color: #000;
}

.form-group label :has(+.form-control:focus):not(.inBorder) {
    color: #80bdff;
    font-weight: 500;
}

.form-control:disabled {
    color: #a8a8a8;
    font-style: italic;
    background-color: #fff;
    cursor: not-allowed;
}

.btn.dropdown-toggle {
    background-color: #fff !important;
    border-color: #ddd !important;
}

@media (max-width: 575px) {
    .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-sm-7 {
        max-width: 58.333333%;
        flex: 0 0 58.333333%;
    }

    .col-sm-5 {
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .form-group label {
        font-size: 12px;
    }

    .form-control {
        font-size: 14px;
    }
}

.badge-delete {
    font-size: 16px;
    padding: 10px;
    margin: 0px;
    font-weight: 700;
    position: absolute;
    left: 7px;
    cursor: pointer;
    z-index: 9;
}

.text-success i.fa-times-circle {
    transform: rotate(45deg);
    font-size: 16px;
    padding: 10px;
    margin: 0px;
    font-weight: 700;
    cursor: pointer;
}

.nav.nav-tabs .nav-link.active {
    color: var(--primary-color) !important;
    border-color: transparent transparent #010c36 transparent;
    border-width: medium;
}

.nav.nav-tabs .nav-link {
    color: var(--primary-color) !important;
}

button.btn-dashboard-link {
    position: absolute;
    top: 10px;
    right: 0;
}

.Test {
    position: absolute;
    top: 25%;
    left: 5px;
    width: auto;
    padding: 0;
}

.inBorder {
    border: none;
    border-bottom: 2px solid lightgray;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.inBorder.form-control {
    border: none;
    border-bottom: 2px solid lightgray;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
}


@media (max-width: 575px) {
    .cellbtn {
        font-size: 14px;
    }
}

ol.breadcrumb {
    margin-bottom: 0;
}

a {
    font-weight: 600;
    color: var(--primary-color);
}

.drop-container {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 200px; */
    height: auto;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
    background: #eee;
    border-color: #111;
}

.drop-container:hover .drop-title {
    color: #222;
}

.drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
}

input[type=file] {
    width: 350px;
    max-width: 100%;
    max-height: 100%;
    color: #444;
    padding: 5px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #555;
}

input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: var(--primary-color);
    padding: 5px 30px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
}

input[type=file]::file-selector-button:hover {
    background: var(--secoundry-color);
}

.fw-500 {
    font-weight: 500;
}

.wimg {
    max-width: 95%;
}

.imgicon {
    position: absolute;
    top: 2%;
    right: 4%;
    font-size: 16PX;
    border-radius: 25PX;
    padding: 2px 7px;
}

@media (max-width: 574px) {
    .imgicon {
        right: 5%;
    }
}

@media (max-width: 767px) {
    .data-object {
        height: auto;
    }

    .width_20 {
        width: 50% !important;
    }
}

@media (min-width: 766px) {
    .data-object {
        height: 300px;
        width: 100%;
    }

    .width_20 {
        width: 20% !important;
    }
}

#form-wizard ol {
    align-items: center !important;
}



.btn.btn-inshover {
    background-color: var(--secoundry-color);
    color: #ffff;
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.btn.btn-inshover:hover {
    color: #fff !important;
    transform: scale(1.05);
    background-color: var(--primary-color);
}

.credit-card {
    width: 300px;
    height: 180px;
    border-radius: 10px;
    background-color: var(--primary-color);
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.credit-card.hovered .card-front {
    transform: translateY(100%);
}

.credit-card.hovered .card-back {
    transform: translateY(0);
}

.card-front,
.card-back {
    width: 100%;
    height: 100%;
    position: absolute;
    transition: transform 0.3s;
}

.card-front {
    z-index: 2;
    display: flex;
    flex-direction: column;
    text-align: left;
    /* padding: 10px; */
}

.card-logo {
    height: 35px;
    background-color: #5356a5;
    border-bottom: 3px solid #fff;
}

.card-back {
    z-index: 1;
    transform: translateY(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #312d2d;
    color: #fff;
}

.card-chip {
    margin-left: 20px;
    padding-top: 3.8px;
}

.text-align-last {
    text-align-last: right;
}

.ps-10 {
    text-align: left;
    /* padding-left: 120px; */
}

.card-number {
    font-size: 18px;
    margin-bottom: 20px;
    letter-spacing: 2px;
}

.card-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card-holder {
    margin-bottom: 3px;
}

.expiry-date {
    margin-bottom: 5px;
}

.label {
    font-size: 12px;
    color: #888;
}

.name .date {
    font-size: 14px;
}

.cvv {
    font-size: 24px;
    font-weight: bold;
}

.float-right {
    float: right !important;
}

.bg-light-custom {
    background-color: var(--primary-color);
}

.width_20 {
    width: 15%;
}

.activein {
    top: 2px;
    right: 60px;
}

.form-check-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.fw-bold {
    font-weight: 650 !important;
}

.btn-check:active+.btn-outline-secondary:focus,
.btn-check:checked+.btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
    box-shadow: 0 0 0 0 !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px 14px !important;
}

/* Apply the outline only when the element is focused (keyboard navigation) */
.customDatePickerClass .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover fieldset.MuiOutlinedInput-notchedOutline,
.customDatePickerClass .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl fieldset.MuiOutlinedInput-notchedOutline {
    border: 1px solid #ced4da !important;
}

.customDatePickerClass input.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 8px 14px !important;
}

.customDatePickerClass button.MuiButtonBase-root.MuiIconButton-root {
    padding: 6px !important;
}

.MuiStack-root:has(.customDatePickerClass) {
    padding-top: 0px;
}

.primaryins {
    position: absolute;
    top: 5.3%;
    width: auto;
    z-index: 9;
    left: 3%;
}




/* common */
.ribbon {
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
    z-index: 3;
}

.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
}

.ribbon span {
    position: absolute;
    display: block;
    width: 155px;
    padding: 1px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: #fff;
    font: 500 12px/1.5 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-align: center;
}

/* top left*/
.ribbon-top-left {
    top: -10px;
    left: -10px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}

.ribbon-top-left::before {
    top: 0;
    right: 0;
}

.ribbon-top-left::after {
    bottom: 0;
    left: 0;
}

.ribbon-top-left span {
    right: -10px;
    top: 25px;
    transform: rotate(-45deg);
}


.custom-image-icon {
    position: absolute;
    right: -17px;
    top: -6px;
    height: 34px;
}

.custom-image-primary {
    position: relative;
    // right: 80px;
    margin-left: 5px;
    top: -4px;
    height: 35px;

}

.custom-image-makeprimary {
    position: relative;
    margin-left: 5px;
    /* right: 104px; */
    top: -5px;
    height: 35px;
}