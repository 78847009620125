:root {
    --primary-color: #6D3580; // Initial value
    --secoundry-color: #CC4165; // --Initial value
}

.card-body a:hover {
    color: var(--primary-color);
}

.card-header {
    text-align: center;
    font-size: 16px;
}

a {
    text-decoration: none !important;
}

.card-custom {
    margin-bottom: 0px;
    transition: all 0.5s;
    padding: 20px 10px;
}

.card-custom:has(.set-1) {
    border-bottom: 5px solid var(--primary-color);
}

.card-custom:has(.set-2) {
    border-bottom: 5px solid var(--secoundry-color);
}

.card-custom:has(.set-3) {
    border-bottom: 5px solid var(--primary-color)
}

.card-custom:has(.set-4) {
    border-bottom: 5px solid #525052;
}

.card-custom h4 {
    margin: 10px 0px;
}

.card-img-top {
    transition: all 0.5s;
}

.card-custom:hover .card-img-top {
    transform: scale(1.25);
}

.set-1 {
    color: var(--primary-color);
}

.set-2 {
    color: var(--secoundry-color);
}

.set-3 {
    color: var(--primary-color)
}

.set-4 {
    color: #5256f3;
}

.bg-thmclr-login {
    color: #fff;
    background-color: var(--primary-color);
    width: 275px;
    transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.bg-thmclr-login:hover {
    color: #fff;
    background-color: var(--primary-color);
    transform: scale(1.15);
}

section {
    padding: 50px 0;
    font-size: 16px;
}

section.landing-banner {
    background: url('../Assets/medical-banner-1.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 500px;
    padding: 0;
}

.h-100px {
    height: 100px;
}

.position-abs-left-0-top-50 {
    font-weight: 700;
    padding: 200px 0;
}

.position-abs-left-0-top-50 h2 {
    color: var(--primary-color);
}

.position-abs-left-0-top-50 h4 {
    color: var(--primary-color);
    opacity: 0.9;
}

section.dashboard-cards .card i.fa {
    font-size: 50px;
}

section.how-to-pay,
section.portal-intro {
    background-color: #fff;
}

section.frequent-questions .card-header>.card-tools {
    position: absolute;
    top: 25%;
    left: 5px;
}

section.frequent-questions .card-tools .btn {
    color: #fff;
}

section.frequent-questions .card-header {
    background-color: #485b99;
    color: #fff;
}

section.frequent-questions .card-header h3.card-title {
    margin-left: 15px;
}

section.frequent-questions .card-body {
    border: 3px solid #485b99;
    border-top: 0;
}

@media (max-width: 767px) {
    section {
        font-size: 14px !important;
    }

    .card-custom {
        margin-bottom: 1rem;
    }
}

/* a:hover {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
} */