.loader-wrapper {
    background-color: #00000088;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
}

.pos-abs-50-50 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.modal-wrapper {
    position: relative;
    min-height: 200px;
    background-color: #8f8f8f33;
}