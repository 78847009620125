:root {
    --primary-color: #6D3580; // Initial value
    --secoundry-color: #CC4165; // --Initial value
}

.card-header {
    text-align: center;
    font-size: 16px;
}

.card-custom:hover {
    background-color: var(--primary-color);
}

.card-custom {
    margin-bottom: 0px;
    transition: all 0.5s;
    padding: 20px 10px;

    :has(.set-1) {
        border-bottom: 5px solid var(--primary-color);
    }

    :has(.set-2) {
        border-bottom: 5px solid var(--secoundry-color);
    }

    :has(.set-3) {
        border-bottom: 5px solid var(--primary-color)
    }

    :has(.set-4) {
        border-bottom: 5px solid #525052;
    }
}

.card-custom h4 {
    margin: 10px 0px;
}

.font-700 {
    font-weight: 700;
}

.card-img-top {
    transition: all 0.5s;
}

.card-custom:hover {

    .set-1,
    .set-1 a,
    .set-2,
    .set-2 a,
    .set-3,
    .set-3 a,
    .set-4,
    .set-4 a {
        color: #fff !important;
    }

    :has(.set-1) {
        background-color: var(--primary-color);
    }

    :has(.set-2) {
        background-color: var(--secoundry-color);
    }

    :has(.set-3) {
        background-color: var(--primary-color)
    }

    :has(.set-4) {
        background-color: #525052;
    }

    .card-img-top {
        transform: scale(1.25);
        color: #fff;
    }

}

.set-1 {
    color: var(--primary-color);
}

.set-2 {
    color: var(--secoundry-color);
}

.set-3 {
    color: var(--primary-color)
}

.set-4 {
    color: #5256f3;
}

section {
    padding: 50px 0;
    font-size: 16px;
}

section.dashboard-cards {
    .card i.fa {
        font-size: 50px;
    }
}

section.how-to-pay,
section.portal-intro,
section.myHealth-users {
    background-color: #fff;
}

section.frequent-questions {
    .card-header.card-tools {
        position: absolute;
        top: 25%;
        left: 5px;
    }

    .card-tools .btn {
        color: #fff;
    }

    .card-header {
        background-color: #485b99;
        color: #fff;

        h3.card-title {
            margin-left: 15px;
        }
    }

    .card-body {
        border: 3px solid #485b99;
        border-top: 0;
    }
}

section.dashboard-cards .row.align-items-center {
    margin: 25px 0;
}

h2.section-title,
h2.section-sub-title,
section.client-content h2,
section.client-content h1 {
    text-transform: capitalize;
    position: relative;
    padding-bottom: 15px;
    font-weight: 400;
    text-align: center;
    line-height: inherit !important;
}

h2.section-title::after,
section.client-content h2::after,
section.client-content h1::after {
    width: 150px;
    height: 1px;
    display: block;
    content: "";
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(var(--primary-color));
}

h2.section-title::before,
section.client-content h2::after,
section.client-content h1::after {
    content: "";
    width: 30px;
    height: 5px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: var(--primary-color);
}

.card-hover-zoom {
    transition: all 0.5s;
    border-radius: 10px;
}

.card-hover-zoom img {
    border-radius: 10px;
}

.card-hover-zoom:hover {
    transform: scale(1.05);
}

ul.ul-users {
    width: 100%;
    display: inline-block;
    position: relative;
    list-style-type: none;
    padding: 0;
}

.ul-users li.w-20 {
    padding: 30px;
    float: left;
    text-align: center;
    cursor: pointer;

}

.cardimg {
    border: 1px solid #aaa;
    border-radius: 15px;
    transition: all 0.5s;
}

.card-why-choose-us {
    text-align: center;
}

@media (max-width: 575px) {
    .d-sm-none.d-md-block {
        display: none !important;
    }

    .d-sm-block.d-md-none {
        display: block !important;
    }

    .mx-sm-2 {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
}

@media (max-width: 991px) {
    .ul-users li.w-20 {
        width: 33%;

    }

    .ul-users li.w-20 img {
        transform: scale(1);
    }

    .ul-users li.w-20 img:hover {
        transform: scale(1.15);
    }

    .absmodal {
        text-align: center;
    }
}

@media (min-width: 992px) {
    .ul-users li.w-20 {
        width: 25%;

    }

    .ul-users li.w-20 img {
        transform: scale(1.15);
    }

    .ul-users li.w-20 img:hover {
        transform: scale(1.25);
    }

    .absmodal {
        display: flex !important;
        align-self: center;
    }
}

@media (max-width: 767px) {
    .ul-users li.w-20 {
        width: 50%;
        padding: 5px;
    }

    .ul-users li.w-20 img {
        transform: scale(1);
    }

    .ul-users li.w-20 img:hover {
        transform: scale(1.05);
    }

    .absmodal {
        text-align: center;
    }

    section {
        font-size: 14px !important;
    }

}

.mdsize {
    font-size: small;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px 14px !important;
}

/* Apply the outline only when the element is focused (keyboard navigation) */
.customDatePickerClass .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover fieldset.MuiOutlinedInput-notchedOutline,
.customDatePickerClass .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl fieldset.MuiOutlinedInput-notchedOutline {
    border: 1px solid #ced4da !important;
}