section {
    padding: 50px 0;
    font-size: 16px;
}

section.tos-header {
    background-color: #485b99c1;
    position: relative;
    padding: 75px 0;

    .container {
        height: 50px;
    }
}

.col-md-8 {
    p:nth-last-child(1) {
        margin: 0;
    }
}

.m-10px {
    margin: 20px 0;
}