:root {
    --primary-color: #6D3580; // Initial value
    --secoundry-color: #CC4165; // --Initial value
}

.main-footer {
    /* z-index: 9999; */
}

@media (min-width: 992px) {
    #main-footer {
        margin-left: 0 !important;
    }
}

@media (min-width: 300px) and (max-width: 576px) {
    .d-sm-none {
        display: none !important;
    }
}

.position-top-25-right-2 {
    position: absolute;
    right: 2%;
}

.ftr-logo img {
    height: 20px;
}

.link {
    color: #5356a5 !important;
    text-decoration: none;
}

.term {
    color: #5356a5 !important;
}

.table.table-borderless {
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-color);
}

table.table.table-borderless td {
    padding: 5px;
}