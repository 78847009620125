.App {
  text-align: center;
}

.thank-you-message {
  opacity: 0;
  animation: fade-in 1s forwards;
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

:root {
  --primary-color: #6D3580; // Initial value
  --secoundry-color: #CC4165; // --Initial value
}