:root {
    --primary-color: #6D3580; // Initial value
    --secoundry-color: #CC4165; // --Initial value
}

.card-align {
    text-align: -webkit-right;
}

.accordion-flush .accordion-item:last-child {
    border-radius: 10px;
    background-color: transparent;
}

.accordion-flush .accordion-item .accordion-button {
    border-radius: 10px !important;
    // background-color: #d6d6d6;
    color: #000;
}

.accordion-body {
    border: 2px solid var(--primary-color) !important;
    margin: 5px !important;
}

.accordion-button {
    padding: 7px !important;
}

.v1 {
    border-left: 4px solid orange !important;
}

.v2 {
    border-left: 4px solid red !important;
}

.v3 {
    border-left: 4px solid green;
}

.pddingset {
    padding: 3px !important;
    border: 0px solid var(--primary-color) !important;
}

.acccheck {
    position: absolute;
    z-index: 9;
    left: 22px;
    width: fit-content;

    input {
        float: unset !important;
    }
}

.accindex {
    position: relative;
}

.success-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pos-abs-0-100 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    right: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: #f3f3f3;
}

.cardcus {
    mask-size: 100%;
    max-height: 60px;
    -webkit-mask-position-y: 74px;
    mask-image: linear-gradient(black calc(110% - 60px), transparent calc(110% - 20px));
    animation-name: fade-enter;
    animation-fill-mode: forwards;
    transition: mask-size 0.3s ease, -webkit-mask-position-y 0.3s ease;
    text-wrap: pretty;
}

.read-more-button {
    display: inline-block;
    // padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    border-radius: 30px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    backdrop-filter: blur(10px);
    background-color: rgba(21, 5, 32, 0.5);
}

.read-more-button:hover {
    background-color: #9a1aff;
}

@keyframes fade-enter {
    0% {
        -webkit-mask-position-y: 120px;
    }

    100% {
        -webkit-mask-position-y: 15px;
    }
}


@media (max-width: 575px) {
    .customfont {
        font-size: 12px;
        white-space: nowrap
    }

    .customfontnotes {
        font-size: 9px;
    }

    .fontnotes {
        font-size: 11px;
    }

    .cusromfontdescription {
        font-size: 11.5px;
    }

    .cusromfontmeasuring_unit {
        font-size: 9px;
    }
}

@media (min-width: 574px) {}

@media (min-width: 768px) {}

@media (max-width: 675px) {}