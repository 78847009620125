:root {
    --primary-color: #6D3580; // Initial value
    --secoundry-color: #CC4165; // --Initial value
}

section {
    padding: 50px 0;
    font-size: 16px;
}

section.privacy-header {
    background-color: #485b99c1;
    position: relative;
    padding: 75px 0;


}

section.privacy-header.container {
    height: 50px;
}

.col-md-8 {
    p:nth-last-child(1) {
        margin: 0;
    }
}

h2.m-10px {
    margin: 20px 0;
}

.card.card-privacy {
    border-bottom: 5px solid var(--secoundry-color);
    transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.card.card-privacy:hover {
    color: #fff !important;

    transform: scale(1.05);
    background-color: var(--secoundry-color);
}

.card.card-privacy:hover a {
    color: #fff !important;
}

h2.section-title {
    text-transform: capitalize;
    position: relative;
    padding-bottom: 15px;
    font-weight: 400;
    text-align: center;
    line-height: inherit !important;
}

h2.section-title::after {
    width: 150px;
    height: 1px;
    display: block;
    content: "";
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--primary-color);
}

h2.section-title::before {
    content: "";
    width: 30px;
    height: 5px;
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    background-color: var(--primary-color);
}

.main-container {
    flex: 1 0 auto;
}