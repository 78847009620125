:root {
  --primary-color: #6D3580; // Initial value
  --secoundry-color: #CC4165; // --Initial value
}


.thmclr {
  color: var(--primary-color) !important;
}

.topnv {
  span {
    margin-top: -5px;
  }

  .ion {
    font-size: 1.8em;
  }
}

.position-sticky-top-0 {
  position: sticky;
  top: 0;
  z-index: 99;
}

.bg-thmclr-login {
  color: #fff;
  background-color: var(--primary-color);
  width: 275px;
  transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.bg-thmclr-login:hover {
  color: #fff;
  background-color: var(--primary-color);
  transform: scale(1.15);
}

section {
  padding: 50px 0;
  font-size: 16px;
}

section.landing-banner {
  background: url("../Assets/medical-banner-1.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 500px;
  padding: 0;
}

.h-100px {
  height: 100px;
}

.position-abs-left-0-top-50 {
  font-weight: 700;
  padding: 200px 0;
}

.position-abs-left-0-top-50 h2 {
  color: var(--primary-color) !important;
}

.position-abs-left-0-top-50 h4 {
  color: var(--primary-color) !important;
  opacity: 0.9;
}

@media (max-width: 575px) {
  .d-sm-none.d-md-block {
    display: none !important;
  }

  .d-sm-block.d-md-none {
    display: block !important;
  }

  .mx-sm-2 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .col-xs-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 574px) {
  /* .d-sm-none.d-md-block {
        display: none !important;
    }

    .d-sm-block.d-md-none {
        display: block !important;
    }

    .mx-sm-2 {
        margin-left: 20px !important;
        margin-right: 20px !important;
    } */

  .col-xs-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .absheadernav {
    display: block !important;
  }
}

.card-hover-zoom {
  transition: all 0.5s;
  border-radius: 10px;
}

.logoimage {
  max-width: 9%;
}

@media (max-width: 675px) {
  .logoimage {
    max-width: 30%;
  }
}